const buyerRoutes = {
  path: "/field_operator/",
  component: () => import("layouts/FieldOperatorPortalLayout.vue"),
  children: [
    {
      path: "orders",
      component: () => import("pages/fieldOperator/FieldOperatorOrders.vue"),
    },
  ],
};

export default buyerRoutes;
