const redirectRoutes = {
  path: "/redirect/",
  children: [
    {
      path: "buyer/:buyerId",
      component: () => import("src/pages/redirectTo/RedirectToBuyerPage.vue"),
      name: "RedirectToBuyerPage",
    },
    {
      path: "master_orders/:masterOrderId",
      component: () =>
        import("src/pages/redirectTo/RedirectToMasterSalesPage.vue"),
      name: "RedirectToLegacyMasterOrderPage",
    },
    {
      path: "sales_orders/:masterOrderId",
      component: () =>
        import("src/pages/redirectTo/RedirectToMasterSalesPage.vue"),
      name: "RedirectToMasterOrderPage",
    },
    {
      path: "sample_orders/:sampleOrderId",
      component: () =>
        import("src/pages/redirectTo/RedirectToSampleOrderPage.vue"),
      name: "RedirectToSampleOrderPage",
    },
  ],
};

export default redirectRoutes;
