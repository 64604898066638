const supplierTeamAdminRoutes = {
  path: "/supplier_team/admin",
  component: () => import("layouts/SupplierTeamAdminPortalLayout.vue"),
  children: [
    {
      path: "dashboard",
      name: "SupplierAdminDashboardPage",
      component: () =>
        import("pages/supplyAdmin/SupplierAdminDashboardPage.vue"),
    },
    {
      path: "profile",
      name: "SupplierAdminProfile",
      component: () => import("pages/supplyAdmin/SupplierAdminProfile.vue"),
    },
    {
      path: "sellers",
      name: "SupplierAdminManageSellers",
      component: () =>
        import("pages/supplyAdmin/SupplierAdminManageSellers.vue"),
    },
    {
      path: "sellers/add",
      name: "SupplierAdminAddSeller",
      component: () => import("pages/supplyAdmin/SupplierAdminAddSeller.vue"),
    },
    {
      path: "sellers/:sellerId",
      name: "SupplierAdminViewSeller",
      component: () => import("pages/supplyAdmin/SupplierAdminViewSeller.vue"),
    },
    {
      path: "sellers/:sellerId/edit",
      name: "SupplierAdminEditSeller",
      component: () => import("pages/supplyAdmin/SupplierAdminEditSeller.vue"),
    },
    {
      path: "sellers/:sellerId/catalog",
      name: "SupplierAdminSellerCatalog",
      component: () =>
        import("pages/supplyAdmin/SupplierAdminSellerCatalog.vue"),
    },
    {
      path: "sellers/:sellerId/categories/:categoryId/skus",
      name: "SupplierAdminViewSellerSKUs",
      component: () =>
        import("pages/supplyAdmin/SupplierAdminViewSellerSKUs.vue"),
    },
    {
      path: "sellers/:sellerId/categories/:categoryId/skus/:skuId",
      name: "SupplierAdminSellerSKUView",
      component: () =>
        import("pages/supplyAdmin/SupplierAdminSellerSKUView.vue"),
    },
    {
      path: "skus",
      name: "SupplierAdminSKUs",
      children: [
        {
          path: "bidso_skus",
          name: "SupplierAdminManageBidsoSKUs",
          component: () =>
            import("pages/supplyAdmin/SupplierAdminManageBidsoSKUs.vue"),
        },
        {
          path: "seller_skus/live",
          name: "SupplierAdminManageLiveSellerSKUs",
          component: () =>
            import("pages/supplyAdmin/SupplierAdminManageLiveSellerSKUs.vue"),
        },
        {
          path: "seller_skus/create",
          name: "SupplierAdminCreateSellerSKU",
          component: () =>
            import("pages/supplyAdmin/SupplierAdminCreateSellerSKU.vue"),
        },
        {
          path: "seller_skus/draft",
          name: "SupplierAdminManageSellerDraftSKUs",
          component: () =>
            import("pages/supplyAdmin/SupplierAdminManageSellerDraftSKUs.vue"),
        },

        {
          path: "seller_skus/:skuId/edit",
          name: "SupplierAdminEditSellerSKU",
          component: () =>
            import("pages/supplyAdmin/SupplierAdminEditSellerSKU.vue"),
        },
      ],
    },

    {
      path: "categories/:categoryId/skus/compare",
      name: "SupplierAdminCompareSKUs",
      component: () => import("pages/supplyAdmin/SupplierAdminCompareSKUs.vue"),
    },
    {
      path: "master_orders/:masterOrderId",
      name: "SupplierAdminViewMasterOrder",
      component: () =>
        import("pages/supplyAdmin/SupplierAdminViewMasterOrder.vue"),
    },
    {
      path: "master_orders",
      name: "SupplierAdminMasterOrdersPath",
      children: [
        {
          path: "",
          name: "SupplierAdminMasterOrdersSearch",
          component: () =>
            import("pages/supplyAdmin/SupplierAdminMasterOrdersSearch.vue"),
        },
        {
          path: "qc/reports",
          name: "SupplierAdminQCReports",
          component: () =>
            import("pages/supplyAdmin/SupplierAdminQCReports.vue"),
        },
        {
          path: "dispatch/calendar",
          name: "SupplierAdminOrderDispatchCalendar",
          component: () =>
            import("pages/supplyAdmin/SupplierAdminOrderDispatchCalendar.vue"),
        },
      ],
    },
  ],
};

export default supplierTeamAdminRoutes;
