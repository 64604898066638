import { route } from "quasar/wrappers";
import {
  createRouter,
  createMemoryHistory,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import routes from "./routes";
import androidSellerAppRoutes from "./androidSellerAppRoutes";
import androidBuyerAppRoutes from "./androidBuyerAppRoutes";
import androidBidsoTeamAppRoutes from "./androidBidsoTeamAppRoutes";

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === "history"
    ? createWebHistory
    : createWebHashHistory;

  function getRoutes() {
    if (process.env.ROUTES_CONFIG === "seller") {
      return androidSellerAppRoutes;
    } else if (process.env.ROUTES_CONFIG === "buyer") {
      return androidBuyerAppRoutes;
    } else if (process.env.ROUTES_CONFIG === "bidso_team") {
      return androidBidsoTeamAppRoutes;
    } else {
      return routes;
    }
  }

  function addBackEvent() {
    // Handle back button press
    window.addEventListener("popstate", () => {
      if (Router?.options?.history?.state?.back === "/") {
        // If on the root route, exit the app
        navigator.app.exitApp(); // Adjust this based on the platform you are targeting
      } else {
        // Navigate back using Vue Router
        // Router.back();
      }
    });
  }

  // scrollBehavior: () => ({ left: 0, top: 0 }),
  const Router = createRouter({
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        // Getting header height
        const headerElement = document.querySelector(".q-header");
        let top = 0;
        if (headerElement) {
          top = (headerElement?.clientHeight || 80) + 20;
        }
        return {
          el: to.hash,
          top: top,
          behavior: "smooth",
        };
      } else {
        window.scrollTo(0, 0);
        return { x: 0, y: 0 };
      }
    },
    routes: getRoutes(),

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  });

  if (process.env.ROUTES_CONFIG === "seller") {
    addBackEvent();
  } else if (process.env.ROUTES_CONFIG === "buyer") {
    addBackEvent();
  } else if (process.env.ROUTES_CONFIG === "bidso_team") {
    addBackEvent();
  }

  return Router;
});
