<template>
  <router-view class="app-background-color" />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
});
</script>
<style></style>
