const toolkitRoutes = {
  path: "/toolkit/",
  component: () => import("layouts/ToolkitLayout.vue"),
  children: [
    {
      path: "labels",
      name: "AnyPrintLabels",
      component: () => import("pages/toolkit/PrintLabels.vue"),
    },
    {
      path: "images",
      name: "AnyImagePlayground",
      component: () => import("pages/toolkit/ImagePlayground.vue"),
    },
    {
      path: "utm",
      name: "AnyUTMTrackingModule",
      component: () => import("pages/toolkit/UserTrackingModule.vue"),
    },
  ],
};

export default toolkitRoutes;
