const defaultRoutes = {
  path: "/",
  component: () => import("layouts/MainLayout.vue"),
  children: [
    { path: "", redirect: "/login" },
    {
      path: "privacy",
      component: () => import("src/pages/PrivacyPolicy.vue"),
    },

    {
      path: "signup",
      component: () => import("src/pages/SignupPage.vue"),
    },
    {
      path: "login",
      component: () => import("src/pages/LoginPage.vue"),
    },

    {
      path: "field_operator/",
      component: () => import("layouts/MainMobileLayout.vue"),
      children: [
        {
          path: "login",
          component: () =>
            import("src/pages/fieldOperator/FieldOperatorLoginPage.vue"),
        },
      ],
    },
    {
      path: "forgot_password",
      component: () => import("src/pages/ForgotPasswordPage.vue"),
    },
    {
      path: "reset_password",
      component: () => import("src/pages/ResetPasswordPage.vue"),
    },
  ],
};

export default defaultRoutes;
